@import '@/assets/scss/mixins';


.root {
    :global(.keen-slider) {
        overflow: visible;
    }
}

.imageWrapper {
    overflow: hidden;
    min-width: 320px;
    max-width: 320px;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen('mobile') {
        min-width: 144px;
        max-width: 144px;
        min-height: 72px;
        max-height: 72px;
    }
}

.image {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 0;
}
